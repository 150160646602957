import Vue from 'vue'
// import TDesign from 'tdesign-vue';
// import 'tdesign-vue/es/style/index.css';
// import '@surely-vue/table/dist/index.less'
import Antd from 'ant-design-vue';
import App from './App.vue';
import router from './router/index.js';
import "./css/tailwindcss.css";
import Vika from './js/vikaRecord.js';
import _ from 'lodash';
import 'ant-design-vue/dist/antd.css';
//VXETable
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import XEUtils from 'xe-utils'

import STable from '@surely-vue/table';
import '@surely-vue/table/dist/index.css'

//格式化单元格内容
VXETable.formats.add('formatterNum', ({ cellValue }, digits = 2) => {
  return XEUtils.commafy(XEUtils.toNumber(cellValue), { digits })
})
VXETable.formats.add('formatTime', ({ cellValue }) => {
  return XEUtils.toDateString(cellValue, 'yyyy-MM-dd')
})


Vue.config.productionTip = false
// Vue.use(TDesign);
Vue.use(Antd);
Vue.prototype.$vika = Vika;
Vue.prototype._ = _
Vue.use(VXETable)
Vue.use(STable)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
