<template>
  <div id="app" class="">
    <router-view></router-view>  
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      
    }
  },
  created() {
    // console.log(this.$router);
  },
}
</script>
<style>
 /deep/ body{
  background-color: #f3f4f6;
}
</style>